import { render, staticRenderFns } from "./IndustryViewBox.vue?vue&type=template&id=258a478f&scoped=true&"
import script from "./IndustryViewBox.vue?vue&type=script&lang=ts&"
export * from "./IndustryViewBox.vue?vue&type=script&lang=ts&"
import style0 from "./IndustryViewBox.vue?vue&type=style&index=0&id=258a478f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258a478f",
  null
  
)

export default component.exports