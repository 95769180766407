import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var IndustryViewBox = (function (_super) {
    __extends(IndustryViewBox, _super);
    function IndustryViewBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop({ required: true })
    ], IndustryViewBox.prototype, "data", void 0);
    IndustryViewBox = __decorate([
        Component
    ], IndustryViewBox);
    return IndustryViewBox;
}(Vue));
export default IndustryViewBox;
