import { __decorate, __extends } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import TabList from '@/components/TabList.vue';
import IndustryViewBox from '@/components/IndustryViewBox.vue';
import Pagination from '@/components/Pagination.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
var IndustryApplication = (function (_super) {
    __extends(IndustryApplication, _super);
    function IndustryApplication() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.page = 1;
        _this.rows = 6;
        return _this;
    }
    IndustryApplication.prototype.mounted = function () {
        this.getApplicationList();
    };
    Object.defineProperty(IndustryApplication.prototype, "navList", {
        get: function () {
            return systemModule.navList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IndustryApplication.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IndustryApplication.prototype, "currentID", {
        get: function () {
            return detailsModule.applicationID;
        },
        set: function (val) {
            detailsModule.setApplicationID(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IndustryApplication.prototype, "applicationList", {
        get: function () {
            return detailsModule.applicationList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IndustryApplication.prototype, "total", {
        get: function () {
            return detailsModule.applicationTotal;
        },
        enumerable: false,
        configurable: true
    });
    IndustryApplication.prototype.pageWatch = function () {
        this.getApplicationList();
    };
    IndustryApplication.prototype.setPage = function () {
        this.page = 1;
    };
    IndustryApplication.prototype.getApplicationList = function () {
        detailsModule.getApplicationList({
            page: this.page,
            rows: this.rows,
            id: this.currentID
        });
    };
    __decorate([
        Watch('$data.page')
    ], IndustryApplication.prototype, "pageWatch", null);
    IndustryApplication = __decorate([
        Component({
            components: { CustomHead: CustomHead, TabList: TabList, IndustryViewBox: IndustryViewBox, Pagination: Pagination }
        })
    ], IndustryApplication);
    return IndustryApplication;
}(Vue));
export default IndustryApplication;
